body.search-open {
  overflow: hidden;
}

@keyframes show {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}
@keyframes hide {
  to {
    transform: translateY(-100%);
  }
}

dialog.search {
  width: 100%;
  height: 100vh;
  overflow: auto;
  top: 0;
  z-index: var(--z-overlay);
  border: none;
  padding: 2.5rem 0px;
  padding-bottom: 10vh;
  background-color: var(--white);
  position: fixed;

  &.opening {
    animation: show 500ms ease-in-out normal;
  }

  &.closing {
    animation: hide 500ms ease-in-out normal;
  }
}

.search__header {
  display: flex;
  align-items: flex-end;

  body.scrolled & {
    align-items: flex-start;
  }
  gap: 3.25rem;
  margin-bottom: 2rem;

  @media (--media-sm-only) {
    gap: 1rem;
  }
}

.search__input {
  flex: 1 1 auto;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  border: none;
  border-radius: 0px;
  border-bottom: 0.125rem solid var(--black);
  padding: 0px 0px 0.425rem 0px;
  min-width: 0;

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  @media (--media-sm-only) {
    font-size: 1.5625rem;
  }
}

.search__close {
  flex: 0 0 3rem;
  display: block;
  height: 3rem;
  width: 3rem;

  .icon {
    height: 3rem;
    width: 3rem;
  }
}

.search__open {
  --icon-fg: var(--black);
  --icon-bg: var(--white);
  flex: 0 0 3rem;
  display: block;
  height: 3rem;
  width: 3rem;

  .icon {
    height: 3rem;
    width: 3rem;
  }

  &:hover {
    --icon-fg: var(--white);
    --icon-bg: var(--black);
  }
}

.search__results {
  /* overflow-y: auto; */

  @media (--media-sm-only) {
    --grid-gap: 1.54rem 0.64rem;
  }
}
