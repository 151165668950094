.footer {
  background: var(--orange);
  color: var(--white);

  & a {
    color: inherit;

    &:hover,
    &.active {
      color: var(--black);
    }
  }

  .button {
    color: var(--black);
  }
}

.footer__container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-between;
  padding-top: 4.5rem;
  padding-bottom: 2.5rem;
}

.footer__social {
  gap: 1rem;
  display: flex;

  --icon-size: 2.5rem;
}

.footer__menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  gap: 0px 0.5rem;
}

.footer__right {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1rem;
}

@media (--media-sm-only) {
  .footer address {
    margin-bottom: 1rem;
  }
  .footer__container {
    flex-direction: column;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .footer__right {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer__menu {
    align-items: flex-start;
  }
}
