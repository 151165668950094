.header {
  background: var(--white);
  box-shadow: var(--shadow);

  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: var(--header-height);
  max-width: 100%;

  transition: height 50ms ease;

  body.scrolled & {
    height: var(--header-height-small);
    transition: height 150ms ease-in-out;
  }
  z-index: var(--z-header);
}

.menu {
  padding-top: 1rem;
  padding-bottom: 0.5rem;

  display: flex;
  align-items: center;
  gap: var(--grid-gap);

  overflow: hidden;

  z-index: var(--z-menu);

  a:hover,
  a.active {
    color: var(--orange);
  }

  body.scrolled & {
    padding-top: 1.75rem;
    padding-bottom: 1.5rem;
  }
}

.menu__toggle {
  place-items: center;
  cursor: pointer;
  display: none;
  --icon-size: 3.5rem;
}

#expand-menu {
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  position: absolute;
}

.menu__logo-link {
  flex-shrink: 0;

  img.menu__logo {
    display: block;
    transition: opacity 650ms ease-in-out;
  }

  img.menu__logo--big {
    width: 14.875rem;
    height: 7.875rem;
    opacity: 1;

    body.scrolled & {
      height: 0px;
      width: auto;
      opacity: 0;
    }
  }

  img.menu__logo--small {
    height: 0px;
    width: auto;
    opacity: 0;

    body.scrolled & {
      height: 1.375rem;
      width: 18.8125rem;
      opacity: 1;
    }
  }
  img.menu__logo--mobile {
    display: none;
    width: 9.30856rem;
    height: 3.07244rem;
  }
}

nav.menu__menu {
  display: flex;
  width: 100%;
  gap: 1.2rem;
  justify-content: flex-end;
  align-items: center;

  span:empty {
    display: none;
  }
}

.menu.menu--mobile,
body.scrolled .menu.menu--mobile {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--blue);
  padding-bottom: 10vh;
  overflow-y: auto;
  transform: translateY(-100%);
  transition: transform 500ms ease-in-out;
}

@media (--media-mobile) {
  .header {
    height: auto !important;
  }

  .menu,
  body.scrolled .menu {
    justify-content: space-between;
    padding-top: 1.63rem;
    padding-bottom: 1.44rem;

    a:hover,
    a.active {
      color: var(--white);
    }
  }

  .menu__toggle {
    display: grid;
  }

  .menu__logo-link {
    flex: 1;

    img.menu__logo--mobile {
      display: block;
    }

    img.menu__logo--small,
    img.menu__logo--big {
      display: none;
    }
  }

  nav.menu__menu {
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 3rem;
  }

  .mobile--header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  #expand-menu:checked + .header {
    .menu.menu--mobile {
      transform: translateY(0%);

      nav.menu__menu {
        display: flex;
      }
    }
  }

  .menu.menu--mobile,
  body.scrolled .menu.menu--mobile {
    display: flex !important;
  }
}
