.prose {
  max-width: var(--grid-content);
  margin-inline: auto;
  h1,
  h2,
  h3 {
    overflow-wrap: break-word;
    hyphens: auto;
    text-wrap: balance;
  }
}

/* .prose.flow:first-child h1 {
  margin-block-start: 2em;
  margin-block-end: 3em;
} */

.prose a:not([class]) {
  color: var(--blue);
  text-decoration: none;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }
}

.figure {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  & img {
    margin: 0 auto;
    flex: 1 1 100%;
  }
}

.figcaption {
  margin-top: 1em;
}

.copyright {
  font-size: 10px;
  opacity: 0.7;
}
