:root {
  --radius: 1.5rem;
}

@keyframes gridReveal {
  to {
    opacity: 1;
  }
}

.js {
  .grid--reveal > .cell {
    opacity: 0;
    animation: 0.5s ease-in calc(var(--delay) * 0.03s) 1 forwards gridReveal;
  }

  @for $i from 1 to 12 {
    .grid--reveal > .cell:nth-child($i) {
      --delay: $i;
    }
  }

  .grid--reveal > .cell:nth-child(n + 12) {
    --delay: 13;
  }
}
