html {
  overscroll-behavior: none;
}

body {
  position: relative;
  font-family: "Be Vietnam Pro", sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.125rem;
  font-weight: 400;
  color: var(--fg);
  background: var(--bg);
  line-height: 1.4;
  padding-top: var(--header-height);

  @media (--media-sm-only) {
    font-size: 1rem;
  }
}

body {
  display: flex;
  flex-flow: column nowrap;
}

.main {
  flex: 1 0 auto;
  min-height: 80vh;
  /* padding-top: 2rem; */

  /* @media (--media-sm-only) {
    padding-top: 1rem;
  } */
}

main,
section#project {
  > .container:last-child {
    margin-bottom: 7rem;

    @media (--media-sm-only) {
      margin-bottom: 3.5rem;
    }
  }
  > div:not(.container) {
    margin-bottom: 0px !important;
  }

  > .container {
    margin-top: 9rem;

    @media (--media-sm-only) {
      margin-top: 4.5rem;
    }
  }
  > .wrapper {
    margin-top: 10rem;

    &.wrapper--striped {
      margin-top: 5rem;

      @media (--media-sm-only) {
        margin-top: 2.5rem;
      }
    }

    + .wrapper {
      margin-top: 0px;
    }

    @media (--media-sm-only) {
      margin-top: 5rem;
    }
  }

  /* Hack for first image in a div */
  > .wrapper:first-child {
    padding-top: var(--header-height);
    margin-top: calc(-1 * var(--header-height));
    background-color: var(--bg);
  }

  > #project {
    margin-bottom: 7rem;

    /* &:empty {
      display: none;
    } */
  }
}

ul {
  margin-block-end: 2em;
}

.prose ul {
  padding-inline-start: 1.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  a {
    color: inherit;
    text-decoration: none;
    transition: color 200ms ease-in-out;

    &:hover {
      color: var(--orange);
    }
  }
}

a {
  transition: color 200ms ease-in-out, background-color 200ms ease-in-out;
}

a[href^="mailto:"] {
  color: initial;
  text-decoration: none;
  transition: color 200ms ease-in-out;

  &:hover {
    color: var(--orange);
  }
}

address {
  text-align: left;
  font-style: normal;
}
