.form {
  --_hp: 1.5rem;

  .errors {
    display: flex;
    align-items: center;
    line-height: 1.3em;

    margin: 1rem 0;
    margin-bottom: 1rem;

    color: var(--error-fg);
  }

  .errorlist {
    color: var(--error-fg);
    padding: 0 var(--_hp);
  }

  .buttons {
    margin-top: 3rem;
  }
}

.field {
  position: relative;
  margin-top: 2.5rem;

  > label.required::after {
    content: " *";
  }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  select,
  textarea,
  .grow-wrap > textarea,
  .grow-wrap::after {
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1.5px solid;
    border-radius: 0px;
    padding: 0.25rem 0;
    background: transparent;

    &:focus,
    &:active {
      color: white;
      outline: none; /* sorry */
    }
  }

  textarea,
  .grow-wrap > textarea {
    resize: vertical;
    max-height: 200px;

    --track-width: 6px;
    --track-color: transparent;
    --thumb-color: black;

    scrollbar-width: var(--track-width);
    scrollbar-face-color: var(--thumb-color);
    scrollbar-track-color: var(--track-color);
    scrollbar-color: var(--thumb-color) var(--track-color);

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar,
    &::-webkit-scrollbar:hover,
    &::-webkit-scrollbar:active {
      width: var(--track-width);
      height: var(--track-width);
      border: none;
      border-radius: 3px;
      opacity: 0.5;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-track:hover,
    &::-webkit-scrollbar-track:active {
      width: var(--track-width);
      height: var(--track-width);
      background: var(--track-color);
      border: none;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb:hover,
    &::-webkit-scrollbar-thumb:active {
      background: var(--thumb-color);
      border: none;
      border-radius: 3px;
      width: var(--track-width);
      height: var(--track-width);
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  select,
  .grow-wrap::after {
    line-height: 1rem;
  }
}

.field.is-invalid {
  & input[type="text"],
  & input[type="email"],
  & input[type="number"],
  & select,
  & textarea {
    border-color: var(--error-fg);
  }
}

.form__step--validated {
  input:invalid,
  select:invalid,
  textarea:invalid {
    border-color: var(--error-fg);
  }
}

.widget--select select {
  appearance: none;
}

.field__help {
  padding: 0.25rem var(--_hp) 0;
}

.widget--radioselect,
.widget--checkboxselectmultiple,
.widget--checkboxinput {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
}

:root {
  --form-control-color: currentColor;
}

/* See https://moderncss.dev/pure-css-custom-styled-radio-buttons/ */
input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  /* transform: translateY(-0.075em); */

  display: grid;
  place-content: center;
  cursor: pointer;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
  cursor: pointer;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}

/* https://moderncss.dev/pure-css-custom-checkbox-style/ */
input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  /* transform: translateY(-0.075em); */

  display: grid;
  place-content: center;
  cursor: pointer;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
  cursor: pointer;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}

input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}

.widget--uploadfileinput {
  & label {
    position: static !important;
  }

  & input {
    margin: 0.5rem 0 0 0.25rem;
  }
}

.widget--hiddeninput {
  display: none;
}

.form__details {
  border: 1px solid;

  & + .form__details {
    border-top: none;
  }
}

.form__summary {
  padding: 1rem;
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;

  h2 {
    padding: 0;
    margin: 0;
  }

  &::before {
    content: "＋";
    font-size: 1.5em;
    line-height: 1;
    display: grid;
    place-items: center;
    transition: transform 0.4s ease;
  }
}

.form__details[open] .form__summary::before {
  transform: rotate(405deg);
}

.form__step {
  border-top: 1px solid;
  padding: 1rem;
}

@media (--media-md) {
  .form__2-2 {
    display: grid;
    gap: 2.5rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: end;
  }

  .form__3-1 {
    display: grid;
    gap: 2.5rem;
    grid-template-columns: minmax(0, 3fr) minmax(0, 1fr);
    align-items: end;
  }

  .form__1-3 {
    display: grid;
    gap: 2.5rem;
    grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
    align-items: end;
  }
}

.form img {
  margin-top: 2.5rem;
}

.square {
  aspect-ratio: 1 / 1;
  display: block;
  height: auto;
  width: 100%;
  object-fit: cover;
}

.widget--clearablefileinputwithpreviewandppoi {
  label {
    width: 100%;
    border: none;
    border-bottom: 1.5px solid;
    border-radius: 0px;
    padding: 0.25rem 0;
    display: inline-block;
    color: var(--fg);
    background-color: transparent;
    background-image: url("../static/images/icon_upload_black.svg");
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 2.25rem;
    cursor: pointer;

    &:focus,
    &:active,
    &:hover {
      color: white;
      outline: none;
      background-image: url("../static/images/icon_upload_white.svg");
    }
  }

  input[type="file"] {
    appearance: none;
    visibility: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
  }
}
