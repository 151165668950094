/* .filter {
} */

.filter--map {
  margin-bottom: 2rem;
}

/* .filter--projects {
} */

.filter__parent {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: flex-start;

  .filter--map & {
    margin-bottom: 1rem;
  }

  /* .filter--projects & {
    justify-content: center;
  } */
}

.filter__child {
  display: flex;
  gap: 0.5rem 1.5rem;
  flex-wrap: wrap;
  justify-content: flex-start;
  min-height: unset;

  @media (--media-lg) {
    gap: 1rem 2.5rem;
    min-height: 35px;
  }
}

.filter[data-show="_"] .button,
.filter .filter__active {
  background: var(--filters);
  color: var(--white);
  border-color: transparent;
}

/* details.filter--select {
  max-width: 100%;
  display: none;
  flex-direction: column;
  gap: 0px;
  border: 1.5px solid var(--fg);
  border-radius: 1.875rem;
  overflow: hidden;

  .button--filter {
    border: none;
    border-radius: 0px;
  }

  .filter--select-options {
    display: flex;
    flex-direction: column;
  }
}

.icon--summary {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
  transition: transform 200ms ease-in-out;

  [open] & {
    transform: translateY(-50%) rotate(-180deg);
  }
}

summary.button--filter {
  position: relative;
  background: var(--button-bg) !important;
  color: var(--button-fg) !important;
  display: block;
}

@media (--media-sm-only) {
  .filter.filter--map {
    display: none;
  }
  details.filter--select {
    display: block;
  }
} */
