:root {
  --grid-columns: 12;
  --grid-gap: 1.5rem;
  --grid-padding: 2rem;
  --grid-container: 82.5rem;
  --grid-container-with-padding: calc(
    var(--grid-container)+ (2 * var(--grid-padding))
  );
  --grid-content: 68.125rem;
  --big--container: 94.5rem;

  /* --grid-prose: 59.5rem; */
  @media (--media-sm-only) {
    --grid-padding: 1rem;
  }
}

.container {
  max-width: var(--grid-container);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--grid-padding);
  padding-right: var(--grid-padding);
}

.container--big {
  max-width: var(--big--container);
  padding-left: 0px;
  padding-right: 0px;
  position: relative; /*  Because of troublemaker */
}

.container .container {
  padding: 0px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(var(--grid-columns), minmax(0, 1fr));
}

.cell {
  --width-sm: var(--grid-columns); /* Default width */
  --width: var(--width-sm);
  grid-column-end: span var(--width);
}

.grid--gapped {
  gap: var(--grid-gap);
}

.grid--padded > :where(.cell) {
  padding: var(--grid-gap);
}

.grid--revert {
  margin: calc(var(--grid-gap) * -1);
}

@media (--media-md) {
  .cell {
    --width-md: var(--width-sm);
    --width: var(--width-md);
  }
}

@media (--media-lg) {
  .cell {
    --width-lg: var(--width-md);
    --width: var(--width-lg);
  }
}

@media (--media-xl) {
  .cell {
    --width-xl: var(--width-lg);
    --width: var(--width-xl);
  }
}

@for $i from 1 to 12 {
  .cell.sm-$(i) {
    --width-sm: $(i);
  }
  .cell.md-$(i) {
    --width-md: $(i);
  }
  .cell.lg-$(i) {
    --width-lg: $(i);
  }
  .cell.xl-$(i) {
    --width-xl: $(i);
  }
}
