.articles {
  --grid-gap: 4.5rem 1.5rem;

  :hover {
    color: var(--blue);
  }

  @media (--media-sm-only) {
    --grid-gap: 3rem 1rem;
  }
}

.articles--featured {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.articles__article {
  position: relative;
  padding: 0;
  background: var(--white);
}

.articles__image {
  width: 100%;
  aspect-ratio: 424 / 380;
  object-fit: cover;
}

.articles__body {
  padding: 1.25rem 1.88rem 4rem 1.88rem;
  overflow-wrap: break-word;
}

.articles__title {
  line-height: 117%; /* 2.55938rem */
}

.articles__dateline {
  margin-bottom: 0.25rem;
  display: block;
}

.gallery__title,
.featured-articles--title,
.more-articles--title {
  margin-bottom: 2.56rem;
}

.article main > div.wrapper.wrapper--striped:nth-of-type(2n) {
  --bg: var(--white);
}

.article__header {
  &.wrapper--blue {
    --fg: var(--white);
  }

  &.wrapper--orange {
    --fg: var(--black);
  }
}

.article__header-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 3.5rem;
  padding-top: 9.375rem;
  padding-bottom: 9.375rem; /* 3.5rem; */
  /* min-height: calc(100vh - var(--header-height)); */
  min-height: 50vh;
  max-height: 100vh;

  @media (--media-sm-only) {
    padding-top: 4rem;
    padding-bottom: 6rem;
    gap: 2.5rem;
  }
}

.article__header-title {
  text-align: center;
}

.article__header-dateline {
  display: block;
}

.article__lead-image img {
  height: calc(100vh - var(--header-height));
  display: block;
  width: 100%;
  object-fit: cover;
}

.article__dateline {
  display: block;
  margin-bottom: 3.5rem;
}

.article__title {
  margin-bottom: 2rem;
}

.prose p.article__lead {
  margin-bottom: 3.5rem;
  font-weight: 500;
}
