.wrapper {
  margin: 0px;
  color: var(--fg);
  background: var(--bg);
  max-width: 100%;
}

.wrapper--content {
  padding-top: 6rem;
  padding-bottom: 7.5rem;

  @media (--media-sm-only) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  &.wrapper--orange {
    padding-top: 10rem;
    padding-bottom: 10rem;

    @media (--media-sm-only) {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  }
}

.wrapper--sticky {
  position: sticky;
  top: calc(var(--header-height-small) - 1px); /* 1 Pixel because some rendering diffs */
  padding-top: 2rem;
  padding-bottom: 2rem;
  z-index: var(--z-menu);
  box-shadow: var(--shadow);

  @media (--media-mobile) {
    position: unset;
    box-shadow: unset;
  }

  @media (--media-sm-only) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.wrapper--white {
  --bg: var(--white);
}

.wrapper--orange {
  --fg: var(--white);
  --button-fg-color: var(--white);
  --bg: var(--orange);
}

.wrapper--blue {
  --bg: var(--blue);
}

.wrapper__inner {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;

  @media (--media-lg) {
    grid-template-columns: 1fr 1fr;
  }
}

/* .wrapper__left {
} */

.wrapper__right {
  padding: 3.4815rem 0px 3.4815rem 4.375rem;
}

.wrapper__top {
  margin-bottom: 8rem;

  @media (--media-sm-only) {
    margin-bottom: 4rem;
  }
}

.newsletter-button .prose {
  text-align: center;
}

.anchor {
  height: 0px;
  width: 100%;
  top: calc(-1 * (var(--header-height, 0px) + 2rem));
  position: relative;
  left: 0;
}

.gallery {
  position: relative;
}

.gallery--with-caption {
  padding-bottom: 3rem;
}

.swiper {
  --swiper-navigation-color: var(--blue);
  --swiper-pagination-color: var(--blue);
  --swiper-pagination-bullet-inactive-color: var(--gray);
  --swiper-pagination-bullet-size: 12px;
  --swiper-navigation-size: 2rem;

  @media (--media-sm-only) {
    --swiper-navigation-size: 30px;
    --swiper-pagination-bullet-size: 9px;
  }

  .swiper-wrapper {
    max-height: calc(var(--grid-content) / 2);
    display: flex;
    align-items: center;
  }

  .swiper-slide {
    display: grid;
    place-items: center;
    height: 100%;
  }

  .swiper-slide img {
    max-height: calc(var(--grid-content) / 2);
    display: block;
    object-fit: contain;
  }
}

.gallery__caption {
  position: absolute;
  left: 50%;
  bottom: calc(3rem - 1em);
  transform: translate(-50%, 100%);
  max-width: calc(var(--grid-content) / 3);
  text-align: center;
}
