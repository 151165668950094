.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: rgba(255 255 255 / 0.7);
  display: grid;
  place-items: center;
  z-index: var(--z-overlay);

  .form {
    overflow: auto;
    max-height: 100vh;

    --track-width: 6px;
    --track-color: transparent;
    --thumb-color: black;

    scrollbar-width: var(--track-width);
    scrollbar-face-color: var(--thumb-color);
    scrollbar-track-color: var(--track-color);
    scrollbar-color: var(--thumb-color) var(--track-color);

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar,
    &::-webkit-scrollbar:hover,
    &::-webkit-scrollbar:active {
      width: var(--track-width);
      height: var(--track-width);
      border: none;
      border-radius: 3px;
      opacity: 0.5;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-track:hover,
    &::-webkit-scrollbar-track:active {
      width: var(--track-width);
      height: var(--track-width);
      background: var(--track-color);
      border: none;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb:hover,
    &::-webkit-scrollbar-thumb:active {
      background: var(--thumb-color);
      border: none;
      border-radius: 3px;
      width: var(--track-width);
      height: var(--track-width);
    }

    @media (--media-sm-only) {
      max-width: unset;
      width: 100%;
      border-radius: 0px;
      height: 100%;
    }
  }
}

.overlay__modal {
  @media (--media-sm-only) {
    width: 100%;
    height: 100%;
  }
}

.overlay__modal .overlay__close {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 3rem;
  height: 3rem;
  --icon-size: 3rem;
  font-size: unset;
}

.form--overlay {
  background: var(--orange);
  color: #000;
  min-width: 50vw;
  max-width: min(90vw, 40rem);

  padding: 2rem 1rem;
  border-radius: 2rem;

  @media (--media-md) {
    padding: 3rem;
    border-radius: 3rem;
  }

  .field:not(.widget--checkboxinput):not(
      .widget--clearablefileinputwithpreviewandppoi
    ) {
    label {
      width: 0px;
      height: 0px;
      position: absolute;
      overflow: hidden;
    }
  }

  .field label {
    cursor: pointer;
  }

  ::placeholder {
    color: inherit;
    opacity: 1;
  }

  .buttons {
    justify-content: center;
  }

  > .prose {
    position: relative;
  }

  .errorlist,
  .errors {
    color: white;
    flex-wrap: wrap;
    position: relative;

    padding-left: 3.75rem;

    &:is(ul, ol) {
      padding-left: 5rem;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      display: inline-block;
      width: 2.25rem;
      height: 2.25rem;
      background: transparent url("../static/images/icon_error.svg") left center
        no-repeat;
      background-size: contain;
    }
  }
}

.overlay__title {
  padding-right: 3.5rem;
}

.overlay__text {
  margin-top: 2.5rem;
}
