.hide {
  display: none !important;
}

.sr-only {
  width: 0px;
  height: 0px;
  position: absolute;
  overflow: hidden;
}

.skip-link:focus-visible {
  height: auto;
  width: auto;
  left: 2rem;
  top: 2rem;
  z-index: var(--z-skiplinks);
  background-color: var(--orange);
  padding: 0.5rem 1rem;
}

.print-only {
  display: none;

  @media print {
    display: unset;
  }
}
