:root {
  --black: #000;
  --white: #fff;

  --fg: var(--black);
  --bg: #faf9f7;

  --filters: #000;

  --error-fg: #ff0000;
  --error-bg: #ffd7d7;
  --warning: var(--orange);
  --border: 1.5px solid var(--fg);
  --shadow: 0 0 4px 0 rgba(0 0 50 / 0.1);

  --z-min: 5;
  --z-menu: 10;
  --z-header: 20;
  --z-overlay: 30;
  --z-skiplinks: 40;

  --gray: #e8e8e8;

  --orange: #ff6207;
  --blue: #5b84ff;
  --green: #3c691c;
  --brown: #d79c48;

  --header-height: 9.375rem;
  --header-height-small: 6.25rem;
  --header-height-mobile: 6.5625rem;

  @media (--media-mobile) {
    --header-height: var(--header-height-mobile);
  }

  --button-fg-color: var(--black);
  --button-bg-color: transparent;
}
