@supports (grid-template-rows: masonry) {
  .sympi {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-template-rows: masonry;
  }

  .sympi__item.cell {
    /* Reset the effect of the cell size classes */
    grid-column-end: span 1 !important;
  }
}

.sympi__item {
  background-color: var(--white);
}

.sympi__item--create {
  .icon {
    --icon-size: 5.5rem;
    background-color: var(--orange);
    padding: 1.5rem;
    border-radius: 3rem;
  }

  .sympi__item-name {
    color: var(--black);
  }
}

.sympi__item-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1 / 1;

  background-color: var(--gray);
}

.sympi__item-content {
  padding: 1rem 1.875rem 1.875rem 1.875rem;
}

.sympi__item-name {
  color: var(--blue);
}

.sympi__item-image {
  width: 100%;
  display: block;
  object-fit: cover;
  aspect-ratio: 1 / 1;
}

.sympi__item-statement {
  margin-top: 1rem;
  max-width: 100%;
  hyphens: auto;

  &::before {
    content: "«";
  }

  &::after {
    content: "»";
  }
}
