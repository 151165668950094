.troublemaker {
  color: var(--white);
  background-color: var(--orange);
  padding: 2rem;
  width: fit-content;
  height: auto; /* fit-content; */
  aspect-ratio: 1 / 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 50%;
  text-align: center;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: var(--z-min);
  box-shadow: var(--shadow);

  @media (--media-sm-only) {
    padding: 1rem;
  }

  @media (--media-lg) {
    top: calc(var(--header-height) + 5rem);
    bottom: auto;
    right: 5rem;
  }

  h3:only-child {
    margin: auto;
  }

  p:only-child {
    margin: auto;
    transform: rotate(-10deg);
  }
}

a.troublemaker {
  transition: background-color 200ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: var(--black);
  }
}

.troublemaker h3 {
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 117%; /* 2.55938rem */
  /* font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 117%;  */

  @media (--media-sm-only) {
    font-size: 1.5rem;
  }
}

/* .troublemaker--map {
  top: 7rem;
  right: 1rem;
  padding: 2rem;

  h3 {
    text-align: center;
    font-family: Be Vietnam Pro;
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 117%;
    @media (--media-mobile) {
      font-size: 1.25rem;
    }
  }

  @media (--media-mobile) {
    top: 3.5rem;
    right: 0;
  }

  @media (--media-sm-only) {
    top: auto;
    right: auto;
    left: 0px;
    bottom: 0px;
    padding: 1rem;
  }
} */
