.theme-plan,
[data-overlay-theme="plan"] {
  --accent: #ff6207;
  --accent-overlay: rgba(255, 98, 7, 0.9);
}

.theme-realestate,
[data-overlay-theme="realestate"] {
  --accent: #5b84ff;
  --accent-overlay: rgba(91, 132, 255, 0.9);
}

.theme-future,
[data-overlay-theme="future"] {
  --accent: #3b691c;
  --accent-overlay: rgba(59, 105, 28, 0.9);
}

.animateOpacity {
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}

@keyframes pulseShadow {
  0%,
  100% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.2;
  }
}

.addGlow {
  transition: filter 200ms ease-in-out;
  display: inline-block;

  [data-glow] {
    animation: pulseShadow 2s infinite;
  }
}

.hideOpacity {
  opacity: 0;
}

#map-svg {
  g {
    pointer-events: none;
  }

  g[data-plan],
  /* g[data-plan-hover], */
    g[data-realestate-hover],
  g[data-future] {
    &:not(.hideOpacity) {
      pointer-events: all;
      cursor: pointer;
    }
  }
}

.map-wrapper {
  position: relative;
}

[data-future] {
  transition: transform 0.3s ease;
  transform-origin: center center;
  transform-box: fill-box;
}

[data-future]:hover {
  transform: scale(1.5);
}

.map-overlay-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0px;
  z-index: var(--z-overlay);

  @media (--media-lg) {
    padding: 3rem;
  }
}

.map-overlay__close {
  right: 1.5rem;
  top: 1.5rem;

  position: absolute;

  --icon-fg: var(--fg);
  --icon-bg: transparent;

  @media (--media-lg) {
    right: 2.25rem;
    top: 2.25rem;
  }
}

.map-overlay {
  --button-fg-color: var(--white);
  --fg: var(--white);
  --bg: var(--accent-overlay);

  position: absolute;
  top: 50%;
  width: 100%;
  min-height: min-content;
  height: auto;
  color: var(--fg);
  box-shadow: 0px 3.6px 3.6px 0px rgba(0, 0, 0, 0.25);
  background: var(--accent, red);

  padding: 1.5rem 6rem 1.5rem 1.5rem;
  border-radius: 1.5rem;
  transform: translateY(-50%);

  ul {
    margin-top: calc(1.125rem + 1.25rem);
    margin-block-end: 4.5rem;
  }

  li {
    margin-top: 1.125rem;
  }

  /* @media (--media-md) {
    border-radius: 3rem;
  } */

  @media (--media-md) {
    background: var(--accent-overlay, red);
  }

  @media (--media-lg) {
    border-radius: 3rem;
    padding: 3.5rem 8rem 3.5rem 3.5rem;
  }
}

.map-overlay__content {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.map-overlay__content--image {
  display: flex;
  align-items: center;
}

.map-overlay__image {
  /* display: block; */
  max-width: 100%;
  background-color: var(--accent);
  border-radius: 1rem;
}

.map-overlay__image {
  /* display: block; */
  max-width: 100%;
  margin-top: auto;
  margin-bottom: auto;
}

.map-overlay__button {
  position: absolute;
  left: 0px;
  bottom: 0px;
}
