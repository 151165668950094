.edit-tools {
  background: var(--blue);
  padding: 0.5rem;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  border: var(--border);

  a {
    color: var(--black);
    &:hover {
      color: var(--white);
    }
  }
}

@media (--media-sm-only) {
  #djDebug {
    display: none;
  }
}
