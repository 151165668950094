/* vietnamese */
@font-face {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/bevietnampro/v11/QdVPSTAyLFyeg_IDWvOJmVES_Hw4BXoYZ7AjSh8.woff2)
    format("woff2");
  unicode-range:
    U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/bevietnampro/v11/QdVPSTAyLFyeg_IDWvOJmVES_Hw5BXoYZ7AjSh8.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/bevietnampro/v11/QdVPSTAyLFyeg_IDWvOJmVES_Hw3BXoYZ7Aj.woff2)
    format("woff2");
  unicode-range:
    U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/bevietnampro/v11/QdVMSTAyLFyeg_IDWvOJmVES_HTEJm86Rb0JcBaoUUU.woff2)
    format("woff2");
  unicode-range:
    U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/bevietnampro/v11/QdVMSTAyLFyeg_IDWvOJmVES_HTEJm87Rb0JcBaoUUU.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/bevietnampro/v11/QdVMSTAyLFyeg_IDWvOJmVES_HTEJm81Rb0JcBao.woff2)
    format("woff2");
  unicode-range:
    U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

h1,
.cta__title {
  font-size: 3.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 117%; /* 3.65625rem */
  /* color: var(--black); */
  text-align: left;

  @media (--media-sm-only) {
    font-size: 2.25rem;
  }
}

h2 {
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 117%; /* 2.925rem */

  @media (--media-sm-only) {
    font-size: 1.5625rem;
    line-height: 140%;
  }
}

.button {
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 3.0625rem */

  @media (--media-sm-only) {
    font-size: 1.5625rem;
  }
}

h3,
.article__header-dateline {
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 3.0625rem */

  @media (--media-sm-only) {
    font-size: 1.5625rem;
  }
}

h4,
.prose p,
.prose li,
.footer address,
.contact__content,
.article__dateline,
.button--map-filter,
.button--map-filter-child,
.button--overlay,
.sympi__item-statement,
input,
textarea,
input + label,
input::before,
input::after,
.field > label {
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 2.1875rem */

  @media (--media-sm-only) {
    font-size: 1.125rem;
  }
}

h5,
.articles__dateline,
figcaption,
.map-overlay__content li,
.sympi__item-name,
.sympi__item-profession,
.gallery__caption {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.575rem */

  @media (--media-sm-only) {
    font-size: 1.125rem;
  }
}

nav a {
  padding: 0.5rem;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 117%; /* 1.4625rem */

  @media (--media-sm-only) {
    font-size: 1.125rem;
  }
}

.header nav a {
  @media (--media-sm-only) {
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 117%; /* 2.6325rem */
  }
}

.prose strong {
  font-weight: 500;
}
