.flow > * {
  @media (--media-lg) {
    max-width: 61.375rem;
    margin-right: auto;
  }
}

.flow > * + * {
  margin-block-start: var(--flow, 1em);
}

h2,
h3 {
  --flow: 2em;
}

.prose :is(h2 + *, h3 + *, h4 + *) {
  --flow: 0.75em;
}
