body {
  --f3cc-accept-foreground: var(--white);
  --f3cc-accept-background: var(--orange);
  --f3cc-background: var(--bg);
  --f3cc-button-foreground: var(--white);
  --f3cc-button-background: var(--blue);
}

.f3cc.f3cc-banner {
  box-shadow: var(--shadow);
}
