.buttons {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 1.5rem;
}

button {
  padding: 0;
  overflow: visible;
  border: 0;
  font: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  color: inherit;
  background: none;
  cursor: pointer;
  appearance: none;
}

.button {
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  text-decoration: none;
  cursor: pointer;
  padding: 0.625rem 0.9375rem;
  border-radius: 5rem;
  border: 1.5px solid var(--button-fg-color);
  color: var(--button-fg-color);
  background: var(--button-bg-color);
  transition: color 200ms ease-in-out, background-color 200ms ease-in-out,
    border-color 200ms ease-in-out;

  &:hover {
    color: var(--bg, --white);
    background: var(--button-fg-color);
  }

  @media (--media-sm-only) {
    font-size: 1.5625rem;
  }
}

.button--small {
  font-size: 1.25rem;
  font-style: normal;
  line-height: 117%; /* 1.4625rem */
  padding: 0.625rem;

  @media (--media-sm-only) {
    font-size: 1.25rem;
  }
}

.button--clean {
  padding: 0px;
  border: none;
  background: none;

  &:hover {
    color: initial;
    border: none;
    background: none;
  }
}

.button--filter {
  border: 1.5px solid var(--button-fg-color);

  &.active,
  &.hover,
  &:hover {
    border: 1.5px solid var(--button-fg-color);
    color: var(--bg, --white);
    background: var(--button-fg-color);
  }
}

.button--map-filter {
  border: 1.5px solid var(--button-fg-color);
  padding: 6px 14px;

  &.active,
  &.hover,
  &:hover {
    border: 1.5px solid var(--accent, var(--black));
    color: var(--bg, --white);
    background: var(--accent, var(--black));
  }
}

.button--map-filter-child {
  border: none;
  padding: 0px;
  background: none;
  text-align: start;

  &.active,
  &.hover,
  &:hover {
    color: var(--accent, red);
    background: none;
  }
}

.button--icon {
  --icon-fg: var(--black);
  --icon-bg: transparent;
  --icon-size: 3rem;
  flex: 0 0 3rem;
  display: block;
  height: 3rem;
  width: 3rem;
  padding: 0px;
  border: none;

  &:hover {
    --icon-fg: var(--white);
    --icon-bg: var(--black);
    background: none;
    border: none;
  }
}

.button--overlay {
  padding: 6px 14px;
}
